import { ContactType } from "./Metadata";

export enum ConsentFormSignerType {
  PROSPECT = 1,
  GAURDIAN1,
  GAURDIAN2,
}

export type ProspectConsentFormDTO = {
  prospectConsentFormId: number;
  prospectId: number;
  signedFileResourceUrl?: string;
  prospectSigningUrl?: string;
  prospectSignedTs?: Date;
  completedTs?: Date;
  guardianSignaturesRequired: boolean;
  eSign: boolean;
  optional: boolean;
};

export type ProspectTppConsentFormDTO = {
  prospectId: number;
  prospectConsentFormId: number;
  blankResourceUrl?: string;
  signedFileName?: string;
  signedResourceUrl?: string;
  hasAcknowledgedDrugTestLanguage?: boolean;
  completedTs?: Date;
};

export type ProspectConsentFormStatusDTO = {
  esign: boolean;
  optional: boolean;
  complete: boolean;
  requiredSignatures: number;
  completedSignatures: number;
  canAccessDocuments: boolean;
  manuallyUploaded: boolean;
};

export type ConsentFormGuardianSignersDTO = {
  guardian1?: ConsentFormSignerDTO;
  guardian2?: ConsentFormSignerDTO;
};

export type ConsentFormSignerDTO = {
  signerTypeId: ConsentFormSignerType;
  contactTypeId: ContactType;
  email?: string;
  phoneNumberCountryId: number;
  phoneNumber: string;
  required: boolean;
  signedTs: Date;
};
